import { Box, Typography, Card } from '@vp/swan';

const Details = () => {
  const data = {
    azp: "C2XeuxGfC4eRlJH8tWENcnnOsXf5cr6A",
    aud: "https://api.cimpress.io/",
    "https://claims.cimpress.io/canonical_id": "Ydpqz1DMLdUf9ncbtXyiQN-F1KAlgjfTQyPIxVuzTdGhE",
    "https://claims.cimpress.io/account": "4HVsAccqLzE6BPbmvrDaKw",
    "https://claims.cimpress.io/is_anonymous": true,
    sub: "Ydpqz1DMLdUf9ncbtXyiQN-F1KAlgjfTQyPIxVuzTdGhE",
    exp: 1741378086,
    iat: 1741291686,
    iss: "https://oauth.cimpress.io/"
  };

  return (
    <Card bordered>
      <Typography as="pre">
        {JSON.stringify(data, null, 2)}
      </Typography>
    </Card>
  );
};

export default Details;
