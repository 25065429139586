import { Box, Typography, Li, Ul, Card} from '@vp/swan';

const ImpTokenClaims = () => {
  return (
    <Card bordered mx = '10'>
    {/* <Box backgroundColor="neutral-100" padding={4} borderRadius="md"> */}
        <Typography fontWeight="bold" py = {3}>Important token claims are:</Typography>
        <Ul>
    <Li> <Typography as="span" textColor='holiday'> canonical_id </Typography> <Typography>See above</Typography></Li>
    <Li> <Typography as="span" textColor='holiday'> is_customer </Typography> <Typography>true if token is for logged-in Vistaprint customer. E.g. ADFS Vistaprint employee tokens do not have this claim.</Typography></Li>
    <Li> <Typography as="span" textColor='holiday'> canonical_id </Typography> <Typography> true if token is for anonymous (not signed-in) Vistaprint customer.</Typography></Li>
  </Ul>
  {/* </Box> */}
  </Card>
  );
};

export default ImpTokenClaims;
