import { Accordion, Box, Card, FlexBox,Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Title } from './title'
import ExampleComponent from './ExampleComponent'
import IdsComponent from './IdsComponent'
import Details from './Details'
import ImpTokenClaims from './ImpTokenClaims'
import JWTComponent from './JWTComponent'
import Form from './Form'
// import TokenUI from './TokenUI'
// import './index.css'
// import JwtTokenDisplay from './JwtTokenDisplay'


export interface Props {
  greeting: string
}

export const Fragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  return (
    <>
    <Box bgc="accent">
    <Form/>
    </Box>
      <FlexBox>
        <Card style={{ width: '100%' }}>
          <Title title='ubik-sample-app' />
          <Typography textAllCaps fontWeight='bold' mb={1} mr={1}>
            {props.greeting} from ubik-sample-app fragment!
          </Typography>
        </Card>
       
      </FlexBox>
    <Typography as="h2" fontWeight="bold" mx='5'>
      Your Access Token
    </Typography>
    <Typography  mx = '5'>
       The following data are taken from the <Typography as ="span" fontWeight="bold">vp/auth</Typography> event onUserIdentityUpdate.
    </Typography>
    <Typography my = '5'  mx= '5'>
      Token is for demo purposes refreshed every 30 seconds: anonymous: 0 times, user: 0 times.
    </Typography>
    <FlexBox>
      <ExampleComponent/>
      <IdsComponent/>
    </FlexBox>
    <Typography as = "h2" mx = {5}>Token claims <Typography as ="span" fontWeight="bold">(<Typography as ="span" textColor="holiday" fontWeight="bold" >profile</Typography> property)</Typography></Typography>
    <FlexBox>
    <Details/>
    <ImpTokenClaims/>
    </FlexBox>
    <JWTComponent/>
     </>
  )
}
