import { Button, Column, Dropdown, DropdownOption, FlexBox, FormField, FormHelper, FormInputGroup, FormLabel, FormLabelOptional, GridContainer, H1, H2, Row, StandardForm, TextArea, TextInput, Typography } from '@vp/swan';

const Form = () => {
  return (
//     <StandardForm variant="horizontal">
//     <GridContainer>
//       <Row component={FormField}>
//         <Column>
//           <FormLabel>
//             Guest Return Url
//           </FormLabel>
//         </Column>
//         <Column span = {3}>
//             <TextInput />
//         </Column>
//         <Column px = '4'>
//             Environment
//         </Column>
//         <Column span = {3}>
//         <Dropdown>
//             <DropdownOption value="A">Option A</DropdownOption>
//             <DropdownOption value="B">Option B</DropdownOption>
//             <DropdownOption value="C">Option C</DropdownOption>
//         </Dropdown>
//         </Column>
//       </Row>
//     </GridContainer>
//   </StandardForm>
    <>
<H2 ml = '6' my = '5'>VP Auth JS - Vanilla JS</H2>
<GridContainer>
  <Row>

  <Typography ml = '6' mt= '4'>Guest Return Url</Typography>

    <Column span={3}>
    <TextInput></TextInput>
    </Column>

    <Typography ml = '6'mr = '4' mt= '4'>Environment</Typography>

    <Column>
    <Dropdown>
        <DropdownOption value="A">Option A</DropdownOption>
        <DropdownOption value="B">Option B</DropdownOption>
        <DropdownOption value="C">Option C</DropdownOption>
    </Dropdown>
    </Column>

    <Typography mr= '4' ml = '6' mt= '4'>Tenant</Typography>
        
    <Column>
    <Dropdown>
        <DropdownOption value="A">Option A</DropdownOption>
        <DropdownOption value="B">Option B</DropdownOption>
        <DropdownOption value="C">Option C</DropdownOption>
    </Dropdown>
    </Column>

    <Typography ml = '6'mr = '4' mt= '4'>Culture</Typography>

    <Column>
        <Dropdown>
        <DropdownOption value="A">Option A</DropdownOption>
        <DropdownOption value="B">Option B</DropdownOption>
        <DropdownOption value="C">Option C</DropdownOption>
        </Dropdown>
    </Column>

    <Typography ml = '6'mr = '4' mt= '4'>Skip Checkout Text</Typography>

    <Column>
        <Dropdown>
        <DropdownOption value="A">Option A</DropdownOption>
        <DropdownOption value="B">Option B</DropdownOption>
        <DropdownOption value="C">Option C</DropdownOption>
        </Dropdown>
    </Column>

  </Row>

    <H1></H1>
  <Row >

  <Typography ml = '6'mr = '4' mt= '4'>Require Session</Typography>

  <Column>
  <Dropdown>
      <DropdownOption value="A">Option A</DropdownOption>
      <DropdownOption value="B">Option B</DropdownOption>
      <DropdownOption value="C">Option C</DropdownOption>
  </Dropdown>
  </Column>

  <Typography mr = '4' ml = '6' mt= '4'>Enable Google One Tap</Typography>
      
  <Column>
  <Dropdown>
      <DropdownOption value="A">Option A</DropdownOption>
      <DropdownOption value="B">Option B</DropdownOption>
      <DropdownOption value="C">Option C</DropdownOption>
  </Dropdown>
  </Column>

  <Typography ml = '6'mr = '4' mt= '4'>Restrict Sign Up</Typography>

  <Column>
      <Dropdown>
      <DropdownOption value="A">Option A</DropdownOption>
      <DropdownOption value="B">Option B</DropdownOption>
      <DropdownOption value="C">Option C</DropdownOption>
      </Dropdown>
  </Column>

  <Typography ml = '6'mr = '4' mt= '4'>Disable Email Field</Typography>

  <Column>
      <Dropdown>
      <DropdownOption value="A">Option A</DropdownOption>
      <DropdownOption value="B">Option B</DropdownOption>
      <DropdownOption value="C">Option C</DropdownOption>
      </Dropdown>
  </Column>

</Row>

    <H1/>


    <Row>

  <Typography ml = '6' mt= '4'>customText JSON</Typography>

        <Column span={2}>
        <TextInput></TextInput>
        </Column>

        <Typography ml = '6'mr = '4' mt= '4'>Enforced Email</Typography>

        <Column span={2}>
        <TextInput></TextInput>
        </Column>

        <Typography mr= '4' ml = '6' mt= '4'>VCS Store Logo URL</Typography>
            
        <Column span={2}>
        <TextInput></TextInput>
        </Column>

    </Row>


    <H1/>


    <Row>

  <Typography ml = '6' mt= '4'>VCS Store URL</Typography>

        <Column span={2}>
        <TextInput></TextInput>
        </Column>

        <Typography ml = '6'mr = '4' mt= '4'>Test User Id</Typography>

        <Column span={2}>
        <TextInput></TextInput>
        </Column>

        <Typography mr= '4' ml = '6' mt= '4'>Unification EntityId</Typography>
            
        <Column span={2}>
        <TextInput></TextInput>
        </Column>

        <Typography ml = '6'mr = '4' mt= '4'>Is First Login
        </Typography>

        <Column span={2}>
        <Dropdown>
            <DropdownOption value = '1'> Option 1</DropdownOption>
            <DropdownOption value = '1'> Option 1</DropdownOption>
        </Dropdown>
        </Column>

    </Row>

    <H1></H1>

    <Row>

    <Typography ml = '6' mt= '4'>Store Id </Typography>

        <Column span={2}>
        <TextInput></TextInput>
        </Column>

        <Button skin = 'primary'>
            Sign In
        </Button>


  </Row>

</GridContainer>
    </>
  )
};

export default Form;
