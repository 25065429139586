import { Box, Typography,Button, Card } from '@vp/swan';

const JWTComponent = () => {
  return (
    <Card bordered my= '7'>
         <Typography as = "H2" my={1} mx = {5}>Raw Token(JWT)</Typography>
    <Box backgroundColor="neutral-100" padding={4} borderRadius="md" mx = {3}>
    <Typography fontFamily="monospace" whiteSpace="pre-wrap" textColor="error">     
  eyJ0eXBlIjoiand0IiwiYWxnIjoiUlMyNTYiLCJraWQiOiJpZUdTNDliOGtIZ19WS0hFM0JZV1Y0MEN4SURpREFHSVJuN2hBSVZEV2ZNIn0.eyJhenAiOiJDMlhldXhHZkM0ZVJsSkg4dFdFTmNubk9zWGY1Y3I2QSIsImF1ZCI6Imh0dHBzOi8vYXBpLmNpbXByZXNzLmlvLyIsImh0dHBzOi8vY2xhaW1zLmNpbXByZXNzLmlvL2Nhbm9uaWNhbF9pZCI6IllkcHF6MURNTGRVZjluY2J0WHlpUU4tRjFLQWxnamZUUXlQSXhWdXpUZEdoRSIsImh0dHBzOi8vY2xhaW1zLmNpbXByZXNzLmlvL2FjY291bnQiOiI0SFZzQWNjcUx6RTZCUGJtdnJEYUt3IiwiaHR0cHM6Ly9jbGFpbXMuY2ltcHJlc3MuaW8vaXNfYW5vbnltb3VzIjp0cnVlLCJzdWIiOiJZZHBxejFETUxkVWY5bmNidFh5aVFOLUYxS0FsZ2pmVFF5UEl4VnV6VGRHaEUiLCJleHAiOjE3NDEzNzgwODYsImlhdCI6MTc0MTI5MTY4NiwiaXNzIjoiaHR0cHM6Ly9vYXV0aC5jaW1wcmVzcy5pby8ifQ.R9zskTbPxT06EJNFvKQ6CI3JlzudP3i_u_maNCeTvxrRCbJRdTrItA17YWyH-hjRcAt8YRR2AB07-yQtZcMpxZNN4Xm-w6LwDoWETIZgbQ2ofbYNxpl7hpFt_lbF6fzWStrV1qEuniV4xXcTJ4t340l66Ohv_8IEuAfmGg_Nr80QE6UbR7cY7mcI6tDrlxlahzeDwcczxaJFnuR1MlDnv08EzMBZoLN-5V9z0P6nIzoo6BTxeGxP2UC_X-bb1cLqOIu1OBh1FWkNUWtA3_ZhPtnctbryq_pRucRqrkQrWBAvo5MDwxrO183mxyzO7dXkY4eZewBbOJW9k2lRFjMHtg
    </Typography>
   <Button>Copy</Button>
  </Box>
  </Card>
  );
};

export default JWTComponent;
