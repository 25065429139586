import { Box, Typography, Link, FlexBox, BoundedContent, Callout, Card } from '@vp/swan';

const ExampleComponent = () => {
  return (
    // <Box border padding={3} borderRadius="medium" mx = '5'>
    // <BoundedContent>
    <Card bordered mx = '5'>
        <FlexBox>
            <Typography>is SignedIn: </Typography>
            <Callout skin="success">True</Callout>
        </FlexBox>
      <Typography marginTop={2}>
        Note that anonymous tokens are cached on Cimpress{' '}
        <Link href="#" textColor="accent">Anonymous service</Link> side.
      </Typography>
     
      </Card>
    // </Box>
  );
};

export default ExampleComponent;
