import { Box, Typography, Link, FlexBox , Button, Callout, Card} from '@vp/swan';

const IdsComponent = () => {
  return (
    <Card bordered mx = '5'>
        <FlexBox>
            <Typography>canoncialId: </Typography>
            <Callout skin = "success">akdfjakjsdflkajsdkgjadkfbnajdlnjansjdfbasbvlzxcnvmzx,dnavmndsbvajbzdjkaks;dnfskndfajsbdfmnzsmdfz.sdn,ad</Callout>
            {/* <Button  mode="compactMode">Copy</Button> */}
        </FlexBox>
        <FlexBox my = '4'>
            <Typography>lastAnonymusId: </Typography>
            <Callout skin = "success">undefined</Callout>
            {/* <Button>Copy</Button> */}
        </FlexBox>
      <Typography marginTop={2}>
        Note that nothing tokens are cached on Cimpress{' '}
        <Link href="#" textColor="accent">Anonymous service</Link> side.
      </Typography>
      <Typography>
      <Link href='#' textColor = "accent" as = 'span'>More information </Link> about canonical_id - customer identifier in the new VP platform.
      </Typography>
      </Card>
  );
};

export default IdsComponent;
